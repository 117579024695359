<template lang="pug">
  .journey-section
    .journey-section__session
      app-checkbox(
        :inputValue="selectedSections"
        :value="section.id"
        :id="section.session.name"
        rounded
        @change="$emit('update:selectedSections', $event)"
      )
      p {{ section.session.name }}
    select-course-leader(
      :options="filteredLeaders"
      :value="section.leader ? section.leader.id : null"
      @input="updateLeader"
    )
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    section: {
      type: Object,
      required: true
    },

    selectedSections: {
      required: true,
      type: Array
    }
  },

  computed: {
    ...mapGetters({
      courseLeaders: 'crmCourseLeaders/leadersList'
    }),

    filteredLeaders() {
      return this.courseLeaders ? this.courseLeaders : []
    },
  },

  methods: {
    updateLeader(val) {
      let section = JSON.parse(JSON.stringify(this.section))
      this.$emit('update:leader', { ...section, leader: val })
    }
  },

  components: {
    selectCourseLeader: () => import('@/components/global/select/SelectCourseLeader.vue'),
    appCheckbox: () => import('@/components/global/Checkbox.vue')
  }
}
</script>

<style lang="scss">
.journey-section {
  display: flex;
  align-items: center;
  font-weight: 500;

  &__session {
    display: flex;
    align-items: center;
    width: 100%;

    p {
      margin: 0;
      padding-left: 17px;
      color: $title-second;
      font-size: 12px;
    }
  }
}
</style>
